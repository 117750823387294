<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <ValidationProvider name="Crop Type Name (En)" vid="name_en"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="name_en"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('VirtualMuseum.configuration.crop_type_en') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="name_en"
                                                        plain
                                                        v-model="cropTypeData.name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Crop Type Name (Bn)" vid="name_bn"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('VirtualMuseum.configuration.crop_type_bn') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="name_bn"
                                                        plain
                                                        v-model="cropTypeData.name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Crop Type Image" vid="type_image">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="type_image"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('VirtualMuseum.configuration.image') }}<span v-if="!id"
                                                                                                           class="text-danger">*</span>
                                                    </template>
                                                    <b-form-file
                                                        id="type_image"
                                                        ref="type_image"
                                                        v-on:change="onFileChange"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    <div class="cust-feedback">
                                                        Please Upload png,gif,jpeg,jpg image
                                                    </div>
                                                    <a v-if="id && cropTypeData.type_image"
                                                       class="btn btn-sm btn-success mt-2" target='_blank'
                                                       :href="virtualMuseumServiceBaseUrl + 'uploads/crop-type/original/' + cropTypeData.type_image">
                                                        <img class="list-image-layout"
                                                             :src="virtualMuseumServiceBaseUrl + 'uploads/crop-type/original/' + cropTypeData.type_image"
                                                             alt="Crop Type Image">
                                                    </a>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">
                                                        {{ saveBtnName }}
                                                    </b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1"
                                                              @click="$bvModal.hide('modal-3')">
                                                        {{ $t('globalTrans.cancel') }}
                                                    </b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<style scope>
    .cust-feedback{
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }
</style>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { cropTypeStore, cropTypeUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getSubSidyPolicyData()
            this.cropTypeData = tmp
        }
    },
    mounted () {
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl,
            cropTypeData: {
                name_en: '',
                name_bn: '',
                type_image: ''
            },
            attachmentDemo: []
        }
    },
    computed: {},
    watch: {},
    methods: {
        onFileChange (e) {
            this.attachmentDemo = e.target.files[0]
        },
        getSubSidyPolicyData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = {
                loading: false,
                listReload: false
            }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            var formData = new FormData()
            Object.keys(this.cropTypeData).map(key => {
                if (key === 'type_image') {
                    formData.append(key, this.attachmentDemo)
                } else {
                    formData.append(key, this.cropTypeData[key])
                }
            })
            if (this.id) {
                result = await RestApi.postData(virtualMuseumServiceBaseUrl, `${cropTypeUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(virtualMuseumServiceBaseUrl, cropTypeStore, formData, config)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false

            if (result.success) {
                this.$store.commit('VirtualMuseum/mutateVirtualMuseumCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-3')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
