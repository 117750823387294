<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('VirtualMuseum.configuration.crop_type_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-3 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns"
                                             aria-hidden="loading | listReload ? 'true' : null"
                                             :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success"
                                                  v-if="data.item.status == 1">{{ $t('globalTrans.active') }}</span>
                                            <span class="badge badge-danger"
                                                  v-else>{{ $t('globalTrans.inactive') }}</span>
                                        </template>
                                        <template v-slot:cell(type_image)="data">
                                            <a target='_blank'
                                               :href="virtualMuseumServiceBaseUrl + 'uploads/crop-type/original/' + data.item.type_image">
                                                <img class="list-image-layout"
                                                     :src="virtualMuseumServiceBaseUrl + 'uploads/crop-type/original/' + data.item.type_image"
                                                     alt="Crop Type Image">
                                            </a>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" :title="$t('globalTrans.edit')" v-b-modal.modal-3 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" :title="$t('globalTrans.active')" v-if="data.item.status == 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" :title="$t('globalTrans.inactive')" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <!-- <b-modal id="modal-5" size="lg" :title="$t('VirtualMuseum.subsidy_circular')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
         <Details :item="item" :key="item"></Details>
       </b-modal> -->
        <b-modal id="modal-3" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only
                 ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { cropTypeList, cropTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {},
            item: '',
            virtualMuseumServiceBaseUrl
        }
    },
    mounted () {
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('VirtualMuseum.configuration.crop_type') + ' ' + this.$t('globalTrans.entry') : this.$t('VirtualMuseum.configuration.crop_type') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                {
                    label: this.$t('globalTrans.sl_no'),
                    class: 'text-left'
                },
                {
                    label: this.$t('VirtualMuseum.configuration.crop_type_name'),
                    class: 'text-center'
                },
                {
                    label: this.$t('VirtualMuseum.configuration.image'),
                    class: 'text-center'
                },
                {
                    label: this.$t('globalTrans.status'),
                    class: 'text-center'
                },
                {
                    label: this.$t('globalTrans.action'),
                    class: 'text-center fixed-width '
                }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'name_bn' },
                    { key: 'type_image' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'name_en' },
                    { key: 'type_image' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        searchData () {
            this.loadData()
        },
        edit (item) {
            this.editItemId = item.id
        },
        details (item) {
            this.item = item
        },
        remove (item) {
            this.changeStatus(virtualMuseumServiceBaseUrl, cropTypeToggleStatus, item, 'virtual_museum_service', 'cropTypeList')
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            })
            this.$store.dispatch('mutateCommonProperties', {
                loading: true,
                listReload: false
            })
            RestApi.getData(virtualMuseumServiceBaseUrl, cropTypeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
                this.$store.dispatch('mutateCommonProperties', {
                    loading: false,
                    listReload: false
                })
            })
        },
        getRelatinalData (data) {
            return data.map(item => {
                return Object.assign({}, item)
            })
        }
    }
}
</script>
<style lang="scss">
.fixed-width {
    width: 180px !important;
}

.list-image-layout {
    height: 80px;
    width: 100px
}
</style>
